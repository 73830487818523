import styled from "styled-components";
import { pallet } from "./base";
import pic from "../images/tim-with-guns.jpg";

const fade = `        cursor: pointer;
&:hover {
    opacity: 0.8;
}
}`;

const MusicItem = styled.div`
    color: ${pallet.darkgold};
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 3rem;
`;

const Card = styled.div`
    background: #ffffff5c;
    border: solid 5px ${pallet.complemantary.darkgold};
    border-radius: 5%;
    text-align: center;
    box-shadow: 1px 5px 5px 5px #97700d78;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${(props) => props.width ?? "min-content"};
    height: max-content;

    .youtube-logo {
        position: relative;
        bottom: 50%;
        color: ${pallet.complemantary.darkgold};
    }
`;

const Image = styled.img`
    max-width: 30vw;
    box-shadow: 1px 1px 1px 5px #1103037d;
    border-radius: 5%;
    margin: 3em;
    opacity: ${(props) => props.opacity || "1"};
`;

const Title = styled.h2`
    color: white;
    font-size: 150%;
    text-shadow: 2px 2px black;
    font-weight: 900;
    width: ${(props) => (props.video ? `100%` : `50% `)};

    ${(props) =>
        props.release ? `background-color: black; border-radius: 15px` : ``};
`;

const Badge = styled.div`
    background: ${pallet.complemantary.shadow};
    height: 3rem;
    width: 8rem;
    color: white;
    border-radius: 10%;
    border solid 1px;
    font-size: 1em;
    left: 45%;
    text-align: center;
`;

const Show = styled.div`
    display: flex;
    background: black;
    font-size: 1.2em;
    border-radius: 20px;
    margin: 1em;
    color: white;
    box-shadow: 1px 1px 1px 2px #ffffff38;
        a {
        color: ${pallet.complemantary.darkgold};
    }
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    svg{
        ${fade}

    }

    span:first-child {
        font-size: 1.5em;
    }
    .show {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        margin: 1em;
        padding: 1em;
        background: ${pallet.complemantary.darkgold};
        color: black;
        font-weight: bold;
        border-radius: 10px;
box-shadow: 1px 1px 1px 2px #ffffff38;        ${fade}
        
`;

const Releases = styled.h1`
    color: white;
    display: flex;
    justify-content: center;
    font-size: 2em;
`;

const animate = {
    container: {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delay: 0.3,
                when: "beforeChildren",
                staggerChildren: 0.1,
            },
        },
    },

    item: {
        hidden: { y: 50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
        },
    },
};

const Bio = styled.div`
    color: white;
    background-image: url(${pic});
    background-size: cover;
    background-position: top;
    background-blend-mode: color;
    background-color: rgba(0, 0, 0, 0.7) !important;
    font-size: 150%;
    padding: 1em;
    .content {
        margin: 10px;
        background: #0000007a;
        border-radius: 50px;
        border: solid white 1px;
        p {
            padding: 3em;
        }
    }
`;

export { MusicItem, Image, Title, Card, Badge, Show, Releases, animate, Bio };
