import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Main, SectionOne, SectionTwo, Events, Video } from "../style/layout";
import { motion } from "framer-motion";
import YouTube from "react-youtube";
import Layout from "../components/layout";
import { VideosContext } from "../context/VideoContext";
import SEO from "../components/seo";
import { Music } from "../components/music";
import { Event } from "../components/event";
import { useStaticQuery, graphql } from "gatsby";
import { Unpaid } from "../components/Unpaid";
import { useAxios } from "../hooks/useAxios";
import { Image } from "../style/components";
import Videos from "../pages/videos";
const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.mute();
};

const onEnd = (event) => {
    event.target.playVideo();
};

const videoOptions = {
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        controls: 0,
        rel: 0,
        showinfo: 0,
        disablekb: true,
        start: "30",
    },
};

const IndexPage = () => {
    const { response, error } = useAxios({
        url: `${process.env.GATSBY_YOUTUBE_URL}/playlistItems`,
        method: "GET",

        params: {
            part: "snippet, contentDetails",
            playlistId: `${process.env.GATSBY_YOUTUBE_PLAYLIST_ID}`,
            key: `${process.env.GATSBY_YOUTUBE_API_KEY}`,
            maxResults: "1",
        },
    });

    const video = response?.data?.items;
    const paid = process.env.GATSBY_PAID;

    if (paid === "false") {
        return <Unpaid />;
    }

    return (
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />{" "}
            <Main>
                <motion.div
                    initial={{ opacity: 0.2 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                >
                    <SectionOne>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 5 }}
                        >
                            {" "}
                            <Link to="/music" title="All Music">
                                <h1>MUSIC</h1>
                            </Link>
                            <Music />
                        </motion.div>
                    </SectionOne>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 6 }}
                >
                    <SectionTwo>
                        <Video>
                            <Link to="/videos" title="All Views">
                                <h1>VIDEOS</h1>
                            </Link>
                            {video && <Videos video={video} />}
                        </Video>
                    </SectionTwo>
                </motion.div>
                {/* <Events >
                    <h1>EVENTS</h1>
                    <Event props={show}></Event>
                </Events> */}
            </Main>
        </Layout>
    );
};
export default IndexPage;
