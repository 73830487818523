import React from "react";
import EventList from "../components/event-list"
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";

const Events = () => {

  //   const shows = useStaticQuery(graphql`
  //   query {
  //    allFile(filter: { sourceInstanceName: { eq: "events" } }) {
  //      edges {
  //        node {
  //          sourceInstanceName
  //          id

  //          childMarkdownRemark {
  //            frontmatter {
  //              title
  //              description
  //              address
  //              age
  //              tickets
  //              date
  //            }
  //          }
  //        }
  //      }
  //    }
  //  }

  //      `);

  return (
    <Layout>
      {/* <EventList props={shows}/> */}
    </Layout>
  )

}
export default Events