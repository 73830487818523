import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { FaYoutube, FaSpotify, FaApple, F } from "react-icons/fa";

const Music = ({ data }) => {
    return (
        <Layout>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column ",
                    justifyContent: "space-evenly",
                    height: "100vh",
                }}
            >
                {" "}
                <h1 style={{ color: "white", textAlign: "center" }}>
                    2xTim's music available for streaming at:
                </h1>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "baseline",
                        flexWrap: "wrap",
                    }}
                >
                    <Link
                        title="Go to Spotify"
                        to="https://open.spotify.com/artist/6xNLWG0LhWCUFcZMpivcYx?si=tUA0dcv0SMSTD-tv_8xMCA"
                    >
                        <FaSpotify color="white" size={"10vh"} />
                    </Link>
                    <Link
                        title="Go to Apple"
                        to="https://music.apple.com/us/artist/2xtim/1508692475"
                    >
                        <FaApple color="white" size={"10vh"} />
                    </Link>
                    <Link
                        title="Go to Youtube"
                        to="https://www.youtube.com/channel/UCuCFBeiCM7OT-JwTwbnQO8Q/videos"
                    >
                        <FaYoutube color="white" size={"10vh"} />
                    </Link>
                </div>
                <h1 style={{ color: "white", textAlign: "center" }}>
                    Outrageous' music available for streaming at:
                </h1>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "baseline",
                        flexWrap: "wrap",
                    }}
                >
                    <Link
                        title="Go to Spotify"
                        to="https://open.spotify.com/artist/7p8pJzD7I3ooJvWIQZ782d"
                    >
                        <FaSpotify color="white" size={"10vh"} />
                    </Link>
                    <Link
                        title="Go to Apple"
                        to="https://music.apple.com/us/artist/outrageous/169269619g"
                    >
                        <FaApple color="white" size={"10vh"} />
                    </Link>
                </div>
            </div>
        </Layout>
    );
};

export default Music;
