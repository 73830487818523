import React from 'react';
import styled from 'styled-components';
import chains from '../images/2x-chains.jpg'

const Container = styled.div`
height: 100vh;
width: 100vw;
display: flex;
flex-direction: column;
justify-content: center;
background-image: url(${props => props.img});
background-size: 100vw auto;
background-repeat: no-repeat;
`

const Text = styled.h1`
color: red;
font-family: Raleway;
text-align: center;
margin-top: 50vw;
font-size: 2em;
width: 100%;
`

Container.Text = Text;

export const Unpaid = () => {

    return (<Container img={chains}>
        <Container.Text>Under Construction</Container.Text>
    </Container>)

}