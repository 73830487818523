import React, { Fragment } from "react";
import styled from "styled-components";
import { FaSoundcloud, FaSpotify, FaApple } from "react-icons/fa";
import { graphql, useStaticQuery } from "gatsby";

const Container = styled.div`
    & img:not(h3) {
        width: 7em;
        height: 4rem;
        padding: 5px;
        flex-direction: row;
    }
`;

const iconSize = "1rem";
// TODO: Use FA library for icons
export const Icon = props => {
    const { content } = props;

    const image = useStaticQuery(graphql`
        {
            apple: file(relativePath: { eq: "applemusic.svg" }) {
                publicURL
                name
            }
            soundcloud: file(relativePath: { eq: "soundcloud.svg" }) {
                publicURL
                name
            }
            spotify: file(relativePath: { eq: "spotify.svg" }) {
                publicURL
                name
            }
        }
    `);

    const SoundCloud = () =>
        content.soundcloudUrl ? (
            <a
                href={content.soundcloudUrl}
                title={`visit: ${content.soundcloudUrl}`}
            >
                <img
                    src={image.soundcloud.publicURL}
                    alt={`${image.soundcloud.name} logo`}
                    height={iconSize}
                />
            </a>
        ) : null;

    const AppleMusic = () =>
        content.appleMusic ? (
            <a href={content.appleMusic} title={`visit: ${content.appleMusic}`}>
                <img
                    src={image.apple.publicURL}
                    alt={`${image.apple.name} logo`}
                    height={iconSize}
                />
            </a>
        ) : null;

    const Spotify = () =>
        content.spotify ? (
            <a href={content.spotify} title={`visit: ${content.spotify}`}>
                <img
                    src={image.spotify.publicURL}
                    alt={`${image.spotify.name} logo`}
                    height={iconSize}
                />
            </a>
        ) : null;

    return (
        <Fragment>
            <Container>
                <SoundCloud></SoundCloud>
                <AppleMusic></AppleMusic>
                <Spotify></Spotify>
            </Container>
        </Fragment>
    );
};
