import React from "react";
import Layout from "../components/layout";
import { Bio as MyBio } from "../style/components";
import { useStaticQuery, graphql } from "gatsby";

const Bio = () => {
    const data = useStaticQuery(graphql`   
 query {
    allFile(filter: { sourceInstanceName: { eq: "bio" } }) {
      edges {
        node {
          sourceInstanceName
          id
  
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  
      
    
    `);

    const bio = data.allFile.edges[0].node.childMarkdownRemark.html

    console.log(data.allFile.edges[0].node.childMarkdownRemark)

    return (
        <Layout>
            <MyBio>
                {" "}
                <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: bio }}></div>
                </div>
            </MyBio>
        </Layout>
    );
};

export default Bio;
