import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Icon } from "./icon";
import { MusicItem, Title, Image, Card } from "../style/components";
import { motion } from "framer-motion";

export const Music = () => {
    const [click, toogle] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            allFile(filter: { sourceInstanceName: { eq: "music" } }) {
                edges {
                    node {
                        id
                        childMarkdownRemark {
                            frontmatter {
                                title
                                spotify
                                date
                                image
                            }
                        }
                    }
                }
            }
        }
    `);

    const edges = Object.keys(data.allFile.edges);
    const music =
        data.allFile.edges[edges.length - 1].node.childMarkdownRemark
            .frontmatter;

    const scale = { scale: 1.1 };
    return (
        <MusicItem key={music.id}>
            <motion.div>
                <Card width={"75vw"} title={`$${music.title}`}>
                    {/* <Title>Latest Release</Title>
                    <Link to={`/music/${music.spotify}`}>
                        <Image src={music.image} />
                    </Link>

                    <Icon content={music}></Icon> */}
                    <iframe
                        style={{ borderRadius: "12px" }}
                        src="https://open.spotify.com/embed/album/6TPKqRi9NAzL3MjIEmfGQl?utm_source=generator&theme=0"
                        width="100%"
                        height="100%"
                        allowFullScreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                    />
                </Card>
            </motion.div>
        </MusicItem>
    );
};

export default Music;
