import React from "react";
import { graphql } from "gatsby";
import { Icon } from "../components/icon";
import Layout from "../components/layout";
import { MusicItem, Title, Image, Card } from "../style/components";

const Music = ({ data }) => {
    const { title, image } = data.contentfulAudio;

    return (
        <Layout>
            <MusicItem>
                <Card title={`${title}`}>
                    <Title release>{title.toUpperCase()}</Title>
                    <Image src={image.file.url} />

                    <Icon content={data.contentfulAudio}></Icon>
                </Card>
            </MusicItem>
        </Layout>
    );
};
export const pageQuery = graphql`
    query($slug: String!) {
        contentfulAudio(slug: { eq: $slug }) {
            title
            slug
            appleMusic
            spotify
            soundcloudUrl
            releaseDate

            image {
                file {
                    url
                }
            }
        }
    }
`;
export default Music;
